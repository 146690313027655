import React from 'react';
import { IconProps } from '.';

const IconCircleArrowRight: React.FC<IconProps> = ({ fill, classes, size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={classes}
      fill={fill ?? 'currentColor'}
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth={2}
      width={size}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z"
      />
    </svg>
  );
};

export default IconCircleArrowRight;

IconCircleArrowRight.defaultProps = {
  size: '16',
};
