import React from 'react';
import { ButtonProps } from 'reactstrap';

const Button: React.FC<ButtonProps & React.HTMLProps<HTMLButtonElement>> = ({
  children,
  ...props
}) => {
  return (
    <button className={'btn action-options-btn'} {...props}>
      {children}
    </button>
  );
};

export default Button;
