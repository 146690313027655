import axios, { AxiosInstance } from 'axios';

class S3 {
  private axios: AxiosInstance;

  constructor() {
    this.axios = axios.create({});
  }

  uploadFile = (url: string, file: File): Promise<any> => {
    const options = {
      headers: {
        'Content-Type':
          file.type || file.type.length !== 0 ? file.type : 'application/octet-stream',
      },
    };

    return this.axios.put(url, file, options);
  };
}

export default S3;
