import React from 'react';
import Skeleton from 'react-loading-skeleton';

const NotificationSkeleton: React.FC = () => {
  return (
    <div className={'selected-notification- pb-4'}>
      <Skeleton height={60} className={'mb-4'} />
      <Skeleton height={600} />
    </div>
  );
};

export default NotificationSkeleton;
