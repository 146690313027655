import React from 'react';
import { IconProps } from '.';

const IconArrowDown: React.FC<IconProps> = ({ fill, classes, size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 9 6"
      className={classes}
      fill={fill ?? 'currentColor'}
      width={size}
    >
      <path d="M5.147 5.227c-.326.334-.843.362-1.2.083l-.094-.083-3.15-3.224C.46 1.754.434 1.37.628 1.095l.073-.087.29-.296c.248-.254.64-.28.916-.076l.088.076L4.5 3.275 7.004.712c.248-.254.64-.28.917-.076l.087.076.29.296c.242.248.267.632.072.907l-.072.088-3.151 3.224z" />
    </svg>
  );
};

export default IconArrowDown;

IconArrowDown.defaultProps = {
  size: '16',
};
