import React from 'react';
import { ButtonProps } from 'reactstrap';

const CancelButton: React.FC<ButtonProps & React.HTMLProps<HTMLButtonElement>> = ({
  children,
  ...props
}) => {
  return (
    <button className={'btn action-options-btn cancel'} {...props}>
      {children}
    </button>
  );
};

export default CancelButton;
